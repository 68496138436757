<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				:class="{
					'container-layout': $screen.width >= 992,
					'container-mobile': $screen.width < 992
				}"
				class="p-0"
			>
				<b-row class="form">
					<b-col cols="12">
						<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
							{{ FormMSG(231, 'Exports') }}
						</h1>
						<b-card no-body class="pb-0">
							<b-row>
								<b-col cols="6">
									<b-card no-body>
										<b-form-group :label="FormMSG(10, 'Export type:')" label-for="exportType" :label-cols="4">
											<b-form-select v-model="exportType" size="md" id="exportType" :options="optionsForExports" @change="getType" />
										</b-form-group>
										<b-card v-if="showDates">
											<b-form-group
												v-if="exportType === 6 || exportType === 7 || exportType === 1 || exportType === 11"
												:label="FormMSG(203, 'Department')"
												:label-cols="12"
												:label-cols-md="4"
												label-for="new-expense_ticket-department"
											>
												<b-form-select
													id="new-expense_ticket-department"
													v-model="selectedDepartment"
													:options="mapDepartments"
													@change="departmentChange"
												/>
											</b-form-group>
											<b-form-group
												v-if="exportType === 1 || exportType === 11"
												:label="FormMSG(208, 'Function')"
												:label-cols="12"
												:label-cols-md="4"
												label-for="new-function"
											>
												<b-form-select id="new-function" v-model="selectedFunction" :options="mapFunctions" @change="functionChange" />
											</b-form-group>
											<b-form-group
												:label="FormMSG(209, 'User')"
												v-if="exportType === 1 || exportType === 11"
												:label-cols="12"
												:label-cols-md="4"
											>
												<v-select
													v-model="selectedUser"
													:options="users"
													label="text"
													:reduce="(option) => option.value"
													:placeholder="FormMSG(210, 'Select user ...')"
												/>
											</b-form-group>
											<b-form-group
												:label="FormMSG(298, 'Session teams')"
												v-if="exportType === 11 && isDeterProject === true"
												:label-cols="12"
												:label-cols-md="4"
											>
												<v-select
													v-model="sessionTeamsSelected"
													:options="sessionOptions"
													label="text"
													multiple
													:reduce="(option) => option.value"
													:placeholder="FormMSG(289, 'Select session team')"
												/>
											</b-form-group>
											<div v-if="exportType != 11">
												<b-form-group v-if="this.initDates" :label="FormMSG(11, 'From:')" label-for="from" :label-cols="4">
													<b-form-input v-model="from" type="date" id="from" required></b-form-input>
												</b-form-group>
												<b-form-group :label="FormMSG(12, 'To:')" label-for="to" :label-cols="4">
													<b-form-input v-model="to" type="date" id="to" required></b-form-input>
												</b-form-group>
											</div>
										</b-card>
										<div :class="`${$screen.width < 768 ? 'd-flex flex-column' : 'd-flex justify-content-end'}`">
											<div :class="`${$screen.width < 768 ? 'col-sm-6 mb-2' : 'mr-3'}`">
												<b-button :block="!$screen.md" variant="outline-primary" @click="downloadExport">
													{{ this.FormMSG(20, 'Download') }}
												</b-button>
											</div>
											<div :class="`${$screen.width < 768 ? 'col-sm-6' : ''}`">
												<b-button :block="!$screen.md" variant="outline-primary" @click="sendExportByEmail">
													{{ this.FormMSG(21, 'Send by email') }}
												</b-button>
											</div>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<loading :active.sync="isLoading" :is-full-page="true"></loading>
						</b-card>
					</b-col>
				</b-row>
				<script-export-modal
					v-model="isScriptReportOpen"
					:items="scripts"
					:custom="customReport"
					:export-type="exportTypeStr"
					@script-export-modal:loading="handleScriptLoading"
					@script-export-modal:closed="handleScriptRerpotOnClosed"
				/>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import languageMessages from '@/mixins/languageMessages';
import { mapGetters, mapActions } from 'vuex';
import { purchaseOrderReport, expenseReport, exportPayroll, userReport, expenseItemReport } from '@/cruds/export.crud';
import globalMixin from '@/mixins/global.mixin';
import { getScripts } from '@/cruds/script.crud';
import { timeSheetReport } from '@/cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { isNil, formatFileName } from '@/shared/utils';
import { getFunctions } from '@/cruds/department.crud';
import { getUsers } from '@/cruds/users.crud';
import DeterMixin from '@/mixins/deter.mixin';
import moment from 'moment';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import _ from 'lodash';
import { getFileExtension } from '@/shared/helpers';

export default {
	name: 'Exports',
	mixins: [languageMessages, globalMixin, DeterMixin],
	components: {
		Loading,
		ScriptExportModal
	},
	data: () => {
		return {
			from: '',
			to: '',
			exportType: 0,
			warning: '',
			erreur: {},
			isLoading: false,
			selectedDepartment: 0,
			// all of types which use REST service, and remove one of them if not use REST
			exportTypeIds: [2, 3, 4, 5, 6, 7, 8],
			isScriptReportOpen: false,
			scriptTimesheetId: 11,
			scriptExpenseId: 4, // will be the expense sheet
			scriptPurchaseOrderId: 9,
			scripts: [],
			customReport: null,
			selectedFunction: 0,
			allFunctions: [],
			selectedUser: null,
			users: [],
			exportTypeStr: 'ts',
			scriptUserId: 12,
			sessionTeamsSelected: [],
			scriptExpenseItemId: 14 // will be the expense
		};
	},
	watch: {
		exportType: {
			handler(val) {
				if (parseInt(val) === 6) {
					this.from = store.state.myProject.startDate.split('T')[0];
				}
			}
		}
	},
	computed: {
		...mapGetters({
			allDepartements: 'expenses/departmentsForSalary'
		}),
		mapDepartments() {
			const departments = this.allDepartements.map((d) => {
				return {
					value: d.value,
					text: d.message
				};
			});
			// return departments;
			const txt = this.FormMSG(204, 'All departments');
			if (+this.exportType === 6) {
				this.selectedDepartment = 0;
				return [{ value: 0, text: txt }, ...departments];
			} else if (+this.exportType === 7 || +this.exportType === 1 || +this.exportType === 11) {
				this.selectedDepartment = -1;
				return [{ value: -1, text: txt }, ...departments];
			}
			// return [{ value: null, text: "Select a department" }, ...departments];
		},
		showDates() {
			let ret = true;
			switch (this.exportType) {
				case 0:
					// "expenseGridFromTo";
					break;
				case 1:
					// "timesheetGridFromTo";
					break;
				case 2:
				case 3:
					// "presenceFromTo";
					break;
				case 4:
					// greenfilm prerequisite report
					ret = false;
					break;
				case 5:
					// greenfilm final report
					ret = false;
					break;
				default:
					break;
			}

			return ret;
		},
		optionsForExports() {
			let options = this.FormMenu(1014);

			if (!store.canAccessBudget()) {
				options = options.filter((option) => option.value !== 6);
			}

			options.sort((a, b) => {
				if (a.text < b.text) {
					return -1;
				}
				if (a.text > b.text) {
					return 1;
				}

				return 0;
			});

			return options;
		},
		initDates() {
			if (this.from.length == 0) {
				const currentDateWithFormat = new Date().toJSON().slice(0, 10);
				this.from = currentDateWithFormat.substring(0, 8) + '01';
				this.to = currentDateWithFormat;
			}

			return true;
		},
		mapFunctions() {
			const functions = this.allFunctions.map((c) => ({
				value: c.value,
				text: c.message
			}));
			var txtSelFunc = this.FormMSG(206, 'Select a function');
			var txtWaiting = this.FormMSG(207, 'Select a department first');
			const text = isNil(this.selectedDepartment) ? txtWaiting : txtSelFunc;
			//  ? "Waiting..."
			//  : "Select a Function";
			return [{ value: 0, text }, ...functions];
		}
	},
	async created() {
		await this.getDepartmentsForSalary();
	},
	methods: {
		...mapActions({
			getDepartmentsForSalary: 'expenses/getDepartmentsForSalary'
		}),
		getExportLinkName() {
			//console.log("this.exportType:",this.exportType);
			let ret = 'undefined';

			switch (this.exportType) {
				case 0:
					ret = 'expenseGridFromTo';
					break;
				case 1:
					ret = 'timesheetGridFromTo';
					break;
				case 2:
				case 3:
					ret = 'presenceFromTo';
					break;
				case 4:
					ret = 'GreenFilmPrerequisiteReport';
					break;
				case 5:
					ret = 'GreenFilmFinalReport';
					break;
				case 6:
					ret = 'Budget';
					break;
				case 7:
					ret = 'carbonPilot';
					break;
				case 8:
					ret = 'carbonFootprintExport';
				case 9:
					ret = 'poGridFromTo';
				default:
					break;
			}

			return ret;
		},
		async sendExportByEmail() {
			this.isLoading = true;
			this.warning = '';

			const fromDate = this.from + 'T00:00:00Z';
			const toDate = this.to + 'T00:00:00Z';

			const config = {
				headers: {
					Authorization: 'Bearer ' + store.state.token
				}
			};

			if (this.exportTypeIds.includes(+this.exportType)) {
				const servicePath = this.getExportLinkName();
				const axiosTmp = require('axios').create();
				const getStr = process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=1';
				// console.log('getStr:', getStr);
				axiosTmp
					// .get(process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=1', config)
					.get(getStr, config)
					.then((response) => {
						// console.log('response=', response);
						if (response.status == 200) {
							this.createToastForMobile(this.FormMSG(111, 'Success'), this.FormMSG(110, 'The document has been sent to your email address.'));
						}
					})
					.catch((error) => {
						console.log({
							error
						});
						if (error.message.includes('Network')) {
							this.warning = this.FormMSG(104, 'Network error.');
						} else if (error.response) {
							if (error.response.status == 401) {
								this.warning = this.FormMSG(103, 'Not connected.');
							} else if (error.response.status == 400) {
								this.warning = this.FormMSG(102, 'Invalid format.');
							} else if (error.response.status == 404) {
								this.warning = this.FormMSG(101, 'No Data in the selected period.');
							}
						}
					})
					.finally(() => (this.isLoading = false));
			} else {
				if (+this.exportType === 9) {
					await purchaseOrderReport({
						startDate: fromDate,
						endDate: toDate,
						sendEmail: true
					})
						.then((result) => {
							if (result) {
								this.createToastForMobile(this.FormMSG(111, 'Success'), this.FormMSG(110, 'The document has been sent to your email address.'));
							}
						})
						.catch((e) => {
							console.error(e);
							this.isLoading = false;
						})
						.finally(() => (this.isLoading = false));
				} else if (+this.exportType === 0) {
					await expenseReport({
						startDate: fromDate,
						endDate: toDate,
						sendEmail: true
					})
						.then((result) => {
							if (result) {
								this.createToastForMobile(this.FormMSG(111, 'Success'), this.FormMSG(110, 'The document has been sent to your email address.'));
							}
						})
						.catch((e) => {
							console.error(e);
							this.isLoading = false;
						})
						.finally(() => (this.isLoading = false));
				}
			}
		},
		async downloadExport() {
			// console.log('indownload')
			this.isLoading = true;
			this.warning = '';

			const fromDate = this.from + 'T00:00:00Z';
			const toDate = this.to + 'T00:00:00Z';

			if (this.exportTypeIds.includes(+this.exportType)) {
				const config = {
					headers: {
						Authorization: 'Bearer ' + store.state.token
					}
				};
				const servicePath = this.getExportLinkName();
				//console.log("servicePath:",servicePath);
				let fullPath = process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=0';

				if (parseInt(this.exportType) === 6 || parseInt(this.exportType) === 7) {
					fullPath = `${process.env.VUE_APP_GQL}/${servicePath}/${this.selectedDepartment}/?From=${fromDate}&To=${toDate}&Email=0`;
				}
				if (parseInt(this.exportType) === 8) {
					fullPath = `${process.env.VUE_APP_GQL}/${servicePath}/?From=${fromDate}&To=${toDate}`;
				}
				// console.log('fullPath:', fullPath);

				const axiosTmp = require('axios').create();

				axiosTmp
					.get(fullPath, config)
					.then((response) => {
						if (response.status == 200) {
							this.forceDownload(response.data);
						}
					})
					.catch((error) => {
						console.log({
							error
						});
						if (error.message.includes('Network')) {
							this.warning = 'Network error.';
						} else if (error.response) {
							if (error.response.status == 401) {
								this.warning = 'Not connected.';
							} else if (error.response.status == 400) {
								this.warning = 'Invalid format.';
							} else if (error.response.status == 404) {
								this.warning = this.FormMSG(22, 'No Data in the selected period.');
							}
						}
					})
					.finally(() => (this.isLoading = false));
			} else {
				if (+this.exportType === 9) {
					this.exportTypeStr = this.EXPORTNAME.PO;

					this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptPurchaseOrderId, true);

					if (this.scripts.length === 0) {
						await this.managePoReport(null, fromDate, toDate);
					} else if (this.scripts.length === 1) {
						await this.managePoReport(null, fromDate, toDate, {
							id: this.scripts[0].id,
							fileName: this.scripts[0].customExportFileName,
							ext: this.getExtensionScript(
								OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
								this.scripts[0].customExtension,
								getFileExtension(this.scripts[0].fileName)
							)
						});
					} else {
						this.isScriptReportOpen = true;
						this.isLoading = false;
						this.customReport = {
							startDate: fromDate,
							endDate: toDate,
							sendEmail: false
						};
					}
				} else if (+this.exportType === 0) {
					this.exportTypeStr = this.EXPORTNAME.EXPENSE;

					this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptExpenseId, true);

					if (this.scripts.length === 0) {
						// await this.manageExpenseReport(null, fromDate, toDate);
						this.createToastForMobile(
							this.FormMSG(87903, 'Warning'),
							this.FormMSG(87904, 'Please create at least one script template to execute correctly this export.'),
							'',
							'warning'
						);
						this.isLoading = false;
					} else if (this.scripts.length === 1) {
						await this.manageExpenseReport(null, fromDate, toDate, {
							id: this.scripts[0].id,
							fileName: this.scripts[0].customExportFileName,
							ext: this.getExtensionScript(
								OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
								this.scripts[0].customExtension,
								getFileExtension(this.scripts[0].fileName)
							)
						});
					} else {
						this.isLoading = false;
						this.isScriptReportOpen = true;
						this.customReport = {
							startDate: fromDate,
							endDate: toDate,
							sendEmail: false
						};
					}
				} else if (+this.exportType === 1) {
					this.exportTypeStr = this.EXPORTNAME.TS;

					this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTimesheetId, true);

					if (this.scripts.length === 0) {
						await this.manageTimesheetReport(
							null,
							fromDate,
							toDate,
							this.selectedDepartment === 0 ? null : this.selectedDepartment,
							this.selectedFunction === 0 ? null : this.selectedFunction,
							this.selectedUser === 0 ? null : this.selectedUser
						).finally(() => {
							this.isLoading = false;
						});
					} else if (this.scripts.length === 1) {
						await this.manageTimesheetReport(
							null,
							fromDate,
							toDate,
							this.selectedDepartment === 0 ? null : this.selectedDepartment,
							this.selectedFunction === 0 ? null : this.selectedFunction,
							this.selectedUser === 0 ? null : this.selectedUser,
							{
								id: this.scripts[0].id,
								fileName: this.scripts[0].customExportFileName,
								ext: this.getExtensionScript(
									OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
									this.scripts[0].customExtension,
									getFileExtension(this.scripts[0].fileName)
								)
							}
						).finally(() => {
							this.isLoading = false;
						});
					} else {
						this.isScriptReportOpen = true;
						this.isLoading = false;
						this.customReport = {
							userDepartment: this.selectedDepartment === 0 ? null : this.selectedDepartment,
							userFunction: this.selectedFunction === 0 ? null : this.selectedFunction,
							userId: this.selectedUser === 0 ? null : this.selectedUser,
							startDate: fromDate,
							endDate: toDate,
							sendEmail: false
						};
					}
				} else if (+this.exportType === 10) {
					await exportPayroll({
						startDate: fromDate,
						endDate: toDate
					})
						.then((result) => {
							this.createToastForMobile(
								this.FormMSG(111, 'Success'),
								this.FormMSG(266, "Datas has been send successfully to sPAIEctacle's server")
							);
						})
						.catch((e) => {
							console.error(e);
							this.isLoading = false;
						})
						.finally(() => (this.isLoading = false));
				} else if (+this.exportType === 11) {
					this.exportTypeStr = this.EXPORTNAME.USER;

					this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptUserId, true);

					if (this.scripts.length === 0) {
						this.createToastForMobile(
							this.FormMSG(87903, 'Warning'),
							this.FormMSG(87904, 'Please create at least one script template to execute correctly this export.'),
							'',
							'warning'
						);
						this.isLoading = false;
					} else if (this.scripts.length === 1) {
						await this.manageUserReport(
							null,
							this.selectedDepartment === 0 ? null : this.selectedDepartment,
							this.selectedFunction === 0 ? null : this.selectedFunction,
							this.selectedUser === 0 ? null : this.selectedUser,
							{
								id: this.scripts[0].id,
								fileName: this.scripts[0].customExportFileName,
								ext: this.getExtensionScript(
									OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
									this.scripts[0].customExtension,
									getFileExtension(this.scripts[0].fileName)
								)
							}
						).finally(() => {
							this.isLoading = false;
						});
					} else {
						this.isScriptReportOpen = true;
						this.isLoading = false;
						this.customReport = {
							userDepartment: this.selectedDepartment === 0 ? null : this.selectedDepartment,
							userFunction: this.selectedFunction === 0 ? null : this.selectedFunction,
							userId: this.selectedUser === 0 ? null : this.selectedUser,
							startDate: fromDate,
							endDate: toDate,
							sendEmail: false
						};

						if (this.isDeterProject === true) {
							this.customReport = _.assign(this.customReport, {
								session: this.sessionTeamsSelected
							});
						}
					}
				} else if (+this.exportType === 12) {
					this.exportTypeStr = this.EXPORTNAME.EXPENSE_ITEM;

					this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptExpenseItemId, true);

					if (this.scripts.length === 0) {
						await this.manageExpenseItemReport(null, fromDate, toDate);
					} else if (this.scripts.length === 1) {
						await this.manageExpenseItemReport(null, fromDate, toDate, {
							id: this.scripts[0].id,
							fileName: this.scripts[0].customExportFileName,
							ext: this.getExtensionScript(
								OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
								this.scripts[0].customExtension,
								getFileExtension(this.scripts[0].fileName)
							)
						});
					} else {
						this.isLoading = false;
						this.isScriptReportOpen = true;
						this.customReport = {
							startDate: fromDate,
							endDate: toDate,
							sendEmail: false
						};
					}
				}
			}
		},
		async manageTimesheetReport(
			subjectId,
			fromDate,
			toDate,
			departmentId,
			functionId,
			userId,
			script = { id: 0, fileName: null, ext: 'xlsx' },
			sendEmail = false
		) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.EXPORT_TS, script.id, script.fileName);

			await timeSheetReport(
				{
					subjectId,
					startDate: fromDate,
					endDate: toDate,
					userDepartment: departmentId,
					userFunction: functionId,
					userId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoading = false));
		},
		async manageExpenseReport(subjectId, fromDate, toDate, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.EXPORT_EXPENSE, script.id, script.fileName);

			await expenseReport(
				{
					subjectId,
					startDate: fromDate,
					endDate: toDate,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					this.forceDownload(result, fileName + '.' + script.ext);
				})
				.catch((e) => {
					console.error(e);
					this.isLoading = false;
				})
				.finally(() => (this.isLoading = false));
		},
		async managePoReport(subjectId, fromDate, toDate, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.EXPORT_PO, script.id, script.fileName);

			await purchaseOrderReport(
				{
					subjectId,
					startDate: fromDate,
					endDate: toDate,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					this.forceDownload(result, fileName + '.' + script.ext);
				})
				.catch((e) => {
					console.error(e);
					this.isLoading = false;
				})
				.finally(() => (this.isLoading = false));
		},
		async manageUserReport(subjectId, departmentId, functionId, userId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.EXPORT_USER, script.id, script.fileName);

			let filter = {
				subjectId,
				userDepartment: departmentId,
				userFunction: functionId,
				userId,
				sendEmail
			};

			if (this.isDeterProject === true) {
				filter = _.assign(filter, {
					session: this.sessionTeamsSelected
				});
			}

			await userReport(filter, script.id)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoading = false));
		},
		async manageExpenseItemReport(subjectId, fromDate, toDate, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE_ITEM, script.id, script.fileName);

			await expenseItemReport(
				{
					subjectId,
					startDate: fromDate,
					endDate: toDate,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					this.forceDownload(result, fileName + '.' + script.ext);
				})
				.catch((e) => {
					console.error(e);
					this.isLoading = false;
				})
				.finally(() => (this.isLoading = false));
		},
		handleScriptRerpotOnClosed() {
			this.isScriptReportOpen = false;
			this.isLoading = false;
		},
		handleScriptLoading(payload) {
			if (payload) {
				this.isLoading = false;
			}
		},
		async departmentChange(departementId) {
			await this.getFunctions(departementId);
			await this.getUsers(departementId);
			this.selectedFunction = 0;
		},
		async getFunctions(id) {
			this.allFunctions = await getFunctions(id);
		},
		async getUsers(departmentId = -1, functionId = 0) {
			const users = await getUsers({ myProject: true }, 0, departmentId, functionId);
			let result = [];
			if (users.length > 0) {
				users.forEach((user) => {
					result.push({
						value: parseInt(user.id, 10),
						text: user.fullName
					});
				});
			}

			this.users = result;
		},
		async getType(payload) {
			if (payload === 1) {
				await this.getUsers();
			}
		},
		async functionChange(functionId) {
			await this.getUsers(this.selectedDepartment, functionId);
		}
	}
};
</script>
